<style lang="scss" scoped>
::v-deep .protect_user_title .text {
	display: block;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex: 1;
	.user_same {
		padding: 0 0.2rem;
		font-size: 0.24rem;
		line-height: 0.5rem;
		text-align: center;
		border-radius: 0.08rem;
		border: 1px solid $color_main;
		color: #fff;
		background-color: $color_main;
	}
	.btns {
		display: flex;
		justify-content: space-between;
		.name_paste {
			margin-right: 0.2rem;
		}
	}
}
::v-deep .cell_title {
	color: $font_color_sec;
	font-size: 0.24rem;
}
::v-deep .cell_value {
	color: $font_color_val;
	font-size: 0.24rem;
}
.user_info_cell {
	align-items: center;
	padding-bottom: 20px;
	.van-cell__label {
		height: 0;
		margin-top: 0;
	}
	.van-cell__title {
		flex: auto;
	}
	.van-cell__value {
		display: flex;
		justify-content: flex-end;
		color: #8b9299;
	}
}
.user_icon {
	width: 0.28rem;
	height: 0.28rem;
	background: url(../../../assets/icon/delete.png);
	background-repeat: no-repeat;
	background-size: 100%;
	background-position: center;
	margin-right: 0.16rem;
}
.user_icon,
.user_title {
	font-size: 0.28rem;
}
.user_title {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.add_user_btn {
	margin: 0.5rem 0.3rem 0.4rem;
	height: 0.9rem;
	border: 1px dashed #dddddd;
	display: flex;
	align-items: center;
	justify-content: center;
	.icon {
		width: 0.23rem;
		height: 0.22rem;
		background: url(../../../assets/icon/add.png);
		background-size: 100%;
		background-repeat: no-repeat;
		background-position: center;
	}
	.text {
		font-size: 0.3rem;
		color: $color_main;
		margin-left: 0.2rem;
	}
}
.user_list_error_text {
	color: $danger;
}
</style>

<template>
	<div class="assureds_2">
		<active-title class="protect_user_title">
			<span v-text="`被保人信息(${userList.length}/200)`"></span>
			<div class="btns">
				<div class="user_same" v-if="sameInsure" @click="userSame">同投保人</div>
			</div>
		</active-title>
		<van-cell-group :border="false" v-show="userList.length">
			<van-cell v-for="(item, index) in userList" :key="index" @click="editUser(item)" title-class="cell_title" value-class="cell_value" class="user_info_cell" :border="true" is-link>
				<span class="user_icon" slot="icon" @click.stop="deleteUser(item.id)"></span>
				<span class="user_title" slot="title">
					<div v-text="item.name"></div>
					<div v-text="item.cardNo"></div>
				</span>
				<span slot="label" class="user_list_error_text">
					<span v-if="item.isError">信息中有误</span>
					<span v-if="item.isError && item.isRepeat">，</span>
					<span v-if="item.isRepeat">证件号码重复</span>
				</span>
			</van-cell>
		</van-cell-group>

		<div class="add_user_btn" @click="addUser">
			<span class="icon"></span>
			<span class="text">新增被保险人</span>
		</div>
	</div>
</template>

<script>
import { Cell, CellGroup, Button, Toast } from 'vant';
import bus from '@/components/bus';
import Mtils from 'mtils';
import { cardTypeMap2 } from '@/config/fixedParams';

export default {
	name: 'assureds2', // 被保人列表，建筑工程一切险使用
	components: {
		[Cell.name]: Cell,
		[CellGroup.name]: CellGroup,
		[Button.name]: Button,
		[Toast.name]: Toast,
	},
	props: {
		// 同投保人按钮
		sameInsure: {
			type: [Boolean, String],
			required: false,
			default: false,
		},
	},
	data() {
		return {
			userList: [], // 被保人列表
		};
	},
	created() {
		this.initBus();
	},
	beforeDestroy() {
		this.clearBus();
	},
	methods: {
		// 获取数据
		getData() {
			return new Promise(resolve => {
				if (this.userList.some(item => item.isRepeat)) {
					Toast('请检查被保人信息');
				} else {
					let data = this.userList.map(item => {
						let assured = Object.assign({}, item);
						if (Mtils.validation.isIdCard(assured.cardNo)) {
							// 身份证
							assured.cardTypeCode = cardTypeMap2['身份证'];
							let idInfo = this.$base.getInfoFromIdNumber(assured.cardNo);
							assured.birthday = idInfo.birthday.replace(/\//g, '-');
							assured.sex = idInfo.sex;
						} else {
							assured.cardTypeCode = cardTypeMap2['统一社会信用代码'];
						}
						return assured;
					});
					if (data.length) {
						resolve(data);
					} else {
						Toast.fail('请添加被保险人');
					}
				}
			});
		},

		// 初始化bus挂载方法
		initBus() {
			console.log('初始化bus');
			bus.$on('receiveInsureData', this.receiveInsureData);
			bus.$on('receiveInsureDataAdd', this.receiveInsureDataAdd);
		},

		// 销毁bus方法
		clearBus() {
			bus.$off('receiveInsureData');
			bus.$off('receiveInsureDataAdd');
		},

		// 接收投保人信息-同投保人
		receiveInsureData(dict) {
			if (!this.userList.some(item => item.cardNo === dict.cardNum)) {
				this.setUserInfo({
					name: dict.userName,
					cardNo: dict.cardNum,
					address: dict.address,
				});
			}
		},

		// 接收投保人信息-新增编辑投保人
		receiveInsureDataAdd(dict) {
			console.log('被保人-新增编辑');
			this.setUserInfo(dict);
		},

		// 新增被保险人-设置数据 idCompensation: id补偿字符，因为循环太快，在1ms中可以计算很多数据，id会重复
		setUserInfo(info, idCompensation = undefined) {
			if (!info.id) {
				info.id = String(Date.now());
				if (idCompensation !== undefined) {
					info.id += idCompensation;
				}
				this.userList.push(info);
			} else {
				this.userList.forEach(item => {
					if (item.id === info.id) {
						Object.assign(item, info);
					}
				});
			}

			// 执行重复检查
			this.validateRepeat();
		},

		// 同投保人
		userSame() {
			bus.$emit('getInsureData');
		},

		// 删除被保人
		deleteUser(id) {
			let ids = this.userList.map(item => item.id);
			let index = ids.indexOf(id);
			this.userList.splice(index, 1);

			// 执行重复检查
			this.validateRepeat();
		},

		// 检查重复的证件号码，该方法会标记出所有的重复信息，比如：有三条一模一样的证件号码，会把这三条全标记出来
		validateRepeat(cb) {
			let repeatInfos = [];
			let idNumList = this.userList.map(row => row.cardNo);
			let repeatIds = [];
			for (let i = 0; i < idNumList.length; i++) {
				const num = idNumList[i];
				let otherNums = idNumList.slice(i + 1);
				let repeatIndex = otherNums.indexOf(num);
				if (repeatIndex !== -1) {
					repeatIds.push(num);
					this.userList[i].isRepeat = true;
					this.userList[i + repeatIndex + 1].isRepeat = true;
					repeatInfos.push(this.userList[i], this.userList[i + repeatIndex + 1]);
				} else {
					if (!repeatIds.includes(num)) {
						this.$set(this.userList[i], 'isRepeat', false);
					}
				}
			}
			if (cb) {
				cb(repeatInfos);
			}
		},

		// 编辑被保人信息
		editUser(info) {
			this.$router.push({
				path: 'addUser2',
				query: Object.assign(this.$route.query, { type: 'edit' }, { userInfo: JSON.stringify(info) }),
			});
		},

		addUser() {
			this.$router.push({
				path: 'addUser2',
				query: { type: 'add' },
			});
		},
	},
};
</script>
